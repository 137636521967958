<template>
    <div class="user-index">
        <div class="main-h1">Города</div>
        <div class="card">
            <div class="card-body">
                <table>
                    <thead>
                    <tr>
                        <th>Город</th>
                        <th>Регион</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="city in cities">
                        <td>{{ city.title }}</td>
                        <td>{{ city.region_number }}</td>
                        <td>
                            <div class="btn red" @click="deleteCity(city.id)">Удалить</div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-fg-1">
                        <label class="form-label">
                            <div class="t">Город</div>
                            <input v-model="city.title" required/>
                        </label>
                    </div>
                    <div class="col-fg-1">
                        <label class="form-label">
                            <div class="t">Регион</div>
                            <input v-model="city.region_number" required/>
                        </label>
                    </div>
                    <div class="col-fg-1">
                        <button :disabled="!btnEnabled" class="btn green" @click="createCity">Создать</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "create",
    data() {
        return {
            cities: [],
            requestInProgress: false,
            city: {
                title: '',
                region_number: '',
            }
        }
    },
    methods: {
        getCities() {
            this.$api.get(`city`).then(res => {
                if (res.data.success) {
                    this.cities = res.data.data;
                }
            })
        },
        deleteCity(city_id) {
            this.$api.delete(`city/${city_id}`).then(res => {
                if (res.data.success) {
                    this.$store.commit('showNotify', {
                        title: 'Удалено',
                        content: 'Успешно удалено.',
                        type: 'success'
                    });
                    this.getCities();
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        createCity() {
            this.requestInProgress = true;
            this.$api.post(`city`, this.city).then(res => {
                this.requestInProgress = false;
                if (res.data.success) {
                    this.claim = res.data.data;
                    this.$store.commit('showNotify', {
                        title: 'Сохранено',
                        content: 'Успешно создано.',
                        type: 'success'
                    });
                    this.getCities();
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
    },
    created() {
        this.getCities();
    },
    computed: {
        btnEnabled: function () {
            return !this.requestInProgress;
        }
    }
}
</script>

<style scoped lang="scss">
table {
    th, td {
        text-align: left;
    }
}

.col-fg-1 {
    display: flex;
    align-items: flex-end;

    .form-label {
        width: 100%;
    }
}

.btn {
    width: 100%;
    height: 32px;
}
</style>